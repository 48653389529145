<template>
  <div>
    <b-modal
      ref="modal-save"
      title="Order Result"
      ok-title="Save"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      @hidden="hiddenModal"
      @ok="handleOk"
      size="lg"
    >
      <validation-observer ref="saveOrderResult">
        <b-form
          ref="form"
          @submit.stop.prevent="saveOrderResult">
          
          <b-form-group
            :label="$t('Result Title')"
          >
           <validation-provider #default="{ errors }" rules="required" name="Ad">
            <b-form-input
              id="resultTitle"
              v-model="modalObject.item.resultTitle"
              :placeholder="$t('Ad')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          
          <b-row>
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                :label="$t('Result Status')"
              >
                <v-select
                  v-model="resultStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="orderResultStatusOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="orderStatus"
                  placeholder="Select Status"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                :label="$t('Region')"
              >
                <v-select
                  v-model="regionId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="presetRegionList"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  label="title"
                  input-id="orderRegion"
                  placeholder="Select Region"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6">
              <b-form-group
                label="Result Desc"
              >
              <validation-provider #default="{ errors }" name="Result Desc">
                <b-form-input
                  v-model="modalObject.item.resultDescription"
                  type="text"
                  placeholder="Result Desc"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <b-form-group :label="$t('Data Version')">
                <v-select
                  v-model="dataVersion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataVersionOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  label="label"
                  input-id="dataVersion"
                  placeholder="Select Version"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group :label="$t('Select Raw Data File')" label-for="orderUploadedFileId">
            <v-select
              v-model="orderUploadedFileId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="files"
              :reduce="(val) => val.id"
              :clearable="true"
              label="fileName"
              placeholder="Select Raw Data File"
              input-id="orderUploadedFileId"/>
          </b-form-group>
          
          <b-form-group
            label="scaled Coordinate"
          >
            <b-form-textarea
              id="textarea-auto-height"
              v-model="modalObject.item.targetCoordinate"
              class="source-input"
              placeholder="Coordinate"
              rows="4"
              no-auto-shrink
            />
          </b-form-group>
          <b-form-group
            label="Unscaled Coordinate"
          >
           <validation-provider #default="{ errors }" name="UnScaled Coordinate">
            <b-form-textarea
              id="textarea-auto-height"
              v-model="modalObject.item.targetUnScaledCoordinate"
              class="source-input"
              placeholder="Coordinate"
              rows="4"
              no-auto-shrink
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
          
          <b-form-group
            label=""
            label-for="contact-options"
            label-class="mb-1">
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="isChangeCoordinate"
                class="custom-control-primary">
                {{ $t('Did the scaled coordinate change?')}}
              </b-form-checkbox>
            </div>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,

      regionId: this.modalObject.item.presetRegionId,
      resultStatus: this.modalObject.item.orderResultStatus,
      dataVersion: this.modalObject.item.dataVersion,
      orderUploadedFileId: this.modalObject.item.orderUploadedFileId,

      orderResultStatusOptions: StaticOptions.orderResultStatusOptions,
      dataVersionOptions: [
        { value: 'D-01', label: 'G25 V1' },
        { value: 'D-02', label: 'DeepA V2' },
      ],

      isChangeCoordinate: false,
    }
  },
  methods: {
    saveOrderResult() {
      this.$refs.saveOrderResult.validate().then(success => {
        if (success) {
          this.modalObject.item.orderResultStatus = this.resultStatus;
          this.modalObject.item.presetRegionId = this.regionId;
          this.modalObject.item.orderUploadedFileId = this.orderUploadedFileId;
          this.modalObject.item.dataVersion = this.dataVersion;

          if (!(this.modalObject.item.targetCoordinate === '' || this.modalObject.item.targetCoordinate === null)) {
              this.UnScaleCoordinate();
          }

          const query = {
            orderResult: this.modalObject.item,
            isChangeCoordinate: this.isChangeCoordinate,
          };

          this.$store.dispatch('orderManagement/saveOrderResult', query, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
              this.modalObject.onSuccess();
              this.hiddenModal();
            } else {
             AlertService.error(this, response.message)
            }
          });
        }
      })
    },

    initialModal() {
      this.$refs['modal-save'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveOrderResult()
    },
    hiddenModal() {
      this.$refs['modal-save'].hide()
      this.modalObject.showModal = false;
    },
    UnScaleCoordinate() {
      /* eslint-disable */
      let eigenvalues = [129.557,103.13,14.222,10.433,9.471,7.778,5.523,5.325,4.183,3.321,2.637,2.246,2.21,1.894,1.842,1.758,1.7,1.605,1.58,1.564,1.557,1.529,1.519,1.452,1.434];

      let lines = this.modalObject.item.targetCoordinate.split('\n');

      // First see how many values are in the first line
      let numVals = lines[0].split(',').length;

      // If less than two values, not a valid input
      if (numVals < 2) {
        return;
      }

      let outputStr = '';

      for (let i = 0; i < lines.length; ++i) {
        if (lines[i].length == 0) {
          continue;
        }

        let vals = lines[i].split(',');

        // Make sure every line has the same number of values
        if (vals.length != numVals) {
          return;
        }

        let newStr = vals[0].replace('_scaled', '');

        for (let j = 1; j < numVals; ++j) {
          let val = parseFloat(vals[j]);

          // If not a number, there must be an error
          if (isNaN(val)) {
            return;
          }

          // Unscale by dividing by the square root of the corresponding eigenvalue
          let unscaled = val / Math.sqrt(eigenvalues[j - 1]);
          newStr += ',' + Number(unscaled.toPrecision(4));
        }

        outputStr += newStr + '\n';
      }

      this.modalObject.item.targetUnScaledCoordinate = outputStr;
    },
  },
  computed: {
    presetRegionList() {
      return this.$store.state.presetRegionManagement.presetRegionList.filter(x => x.presetRegionType === 1);
    },    
    files() {
      return this.$store.getters['orderManagement/getOrderUploadedFiles']
    },
  },
  mounted() {
    this.initialModal();
  },
  created() {
    this.$store.dispatch('presetRegionManagement/fetchPresetRegionList', {});
  },
};
</script>
